<template>
  <div>
    <loader
      v-if="Loadmore"
      object="#9658cc"
      color1="#532a76"
      color2="#d3bae9"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="30"
      name="circular"
    />
    <!-- <this-header /> -->
    <div class="container-sm-lot pl-1 pr-1">
      <div class="top-bxt my-1">
        <img
          src="/icon/back.svg"
          alt="back"
          class="cursor-pointer ts-left"
          @click="$router.go(-1)"
        >

        <h3 class="text-primary mb-0">
          ข้อมูลส่วนตัว
        </h3>
      </div>

      <div class="d-flex align-items-center justify-content-start mt-2">
        <div class="mr-1">
          <div
            class="user-icon"
            style="position: relative"
          >
            <img
              :src="ShowProfile()"
              alt="img-user"
              class="ic-profile-2"
            >

            <span
              class="edit-profile"
              @click="uploadProfile"
            >
              <i class="fas fa-pen" />
            </span>
          </div>
        </div>

        <div>
          <h3 class="mb-0 text-primary">
            {{ userData.username }}
          </h3>

          <p class="text-muted mb-0">
            รหัสลูกค้า #{{ userData.invite_code }}
          </p>
        </div>
      </div>

      <div class="mt-2">
        <div class="d-flex align-items-center justify-content-between">
          <h3 class="text mb-0">
            บัญชีของฉัน
          </h3>

          <u
            v-if="profile.bankname || profile.acc_no || profile.acc_name"
            class="text-primary cursor-pointer"
            @click="$refs['add-profile'].show()"
          >แก้ไขข้อมูล</u>
        </div>

        <div
          v-if="!profile.bankname && !profile.acc_no && !profile.acc_name"
          class="text-center my-4"
        >
          <button
            class="btn-gradient-gray"
            @click="$refs['add-profile'].show()"
          >
            <i class="fas fa-plus" /> เพิ่มข้อมูลของฉัน
          </button>
        </div>

        <div
          v-if="profile.bankname || profile.acc_no || profile.acc_name"
          class="pl-1 pr-1"
        >
          <table class="w-100">
            <tbody>
              <tr>
                <td class="pt-50 pb-50">
                  <p class="mb-0 text-primary">
                    ธนาคาร
                  </p>
                </td>
                <td class="pt-50 pb-50">
                  <input
                    id=""
                    v-model="profile.bankname"
                    type="text"
                    disabled
                    class="form-profile w-100"
                  >
                </td>
              </tr>

              <tr>
                <td class="pt-50 pb-50">
                  <p class="mb-0 text-primary">
                    เลขที่บัญชี
                  </p>
                </td>
                <td class="pt-50 pb-50">
                  <input
                    id=""
                    v-model="profile.acc_no"
                    type="text"
                    disabled
                    class="form-profile w-100"
                  >
                </td>
              </tr>

              <tr>
                <td class="pt-50 pb-50">
                  <p class="mb-0 text-primary">
                    ชื่อบัญชี
                  </p>
                </td>
                <td class="pt-50 pb-50">
                  <input
                    id=""
                    v-model="profile.acc_name"
                    type="text"
                    disabled
                    class="form-profile w-100"
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="card-withdraw mt-1">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <img
              src="@/assets/images/newIcon/coin.png"
              height="50"
            >

            <div class="ml-1">
              <label class="text-primary"> จำนวนเงินคงเหลือ </label>

              <h3 class="text-primary mb-0 mt-n2 font-large-2">
                {{ Commas(balance.balance) }} <small class="txt-warn">₩</small>
              </h3>

              <div class="mt-n1">
                <small class="mb-50 text-primary">
                  {{ balance_datecheck ? balance_datecheck : "-" }}</small>
              </div>
            </div>
          </div>

          <i
            class="far fa-redo-alt text-primary cursor-pointer"
            @click="getBalance"
          />
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-start mt-2">
        <div class="w-50 pr-1">
          <router-link :to="{ name: 'bank' }">
            <button class="btn-gray-txt p-25 w-100">
              ฝากเงิน
            </button>
          </router-link>
        </div>

        <div class="w-50 pl-1">
          <router-link :to="{ name: 'withdraw' }">
            <button class="btn-gray-txt p-25 w-100">
              ถอนเงิน
            </button>
          </router-link>
        </div>
      </div>

      <!-- <div class="text-center mt-2">
        <h5 class=" text-primary mb-0">
          ประวัติการทำรายการ
        </h5>
      </div> -->

      <div class="mt-4 text-center">
        <button
          class="btn-logout"
          @click="$refs['modal-confirm-log-out'].show()"
        >
          <i class="far fa-power-off" /> <span>ออกจากระบบ</span>
        </button>
      </div>

      <b-modal
        ref="add-profile"
        hide-footer
        hide-header
        centered
      >
        <validation-observer ref="setprofile">
          <div>
            <div class="closed">
              <i
                class="far fa-times cursor-pointer icon-close"
                @click="$refs['add-profile'].hide()"
              />
            </div>

            <h4 class="mb-0 text-primary mt-50 text-center">
              กรอกข้อมูลบัญชีของฉัน
            </h4>

            <div class="mt-1">
              <label
                for="Email"
                class="text-primary"
              > ธนาคาร </label>

              <div
                class="box-bank"
                @click="$refs['my-modal'].show()"
              >
                <div
                  v-if="profile.bank && !bank.code"
                  class="img-bank"
                >
                  <img
                    :src="`/bank/${
                      profile.bank ? `${profile.bank}.jpg` : 'bank.png'
                    }`"
                    :alt="`${profile.bank}`"
                    height="25"
                  >
                </div>
                <div
                  v-if="bank.code"
                  class="img-bank"
                >
                  <img
                    :src="`/bank/${
                      bank.code ? `${bank.code}.jpg` : 'bank.png'
                    }`"
                    :alt="`${bank.code}`"
                    height="25"
                  >
                </div>

                <div
                  v-if="!bank.code"
                  class="detail-bank"
                  :class="bank.code ? 'ml-1' : ''"
                >
                  <!-- <p class="mb-0"> -->
                  {{ profile.bankname ? profile.bankname : "เลือกธนาคาร" }}
                  <!-- </p> -->
                </div>
                <div
                  v-if="bank.code"
                  class="detail-bank"
                  :class="bank.code ? 'ml-1' : ''"
                >
                  <!-- <p class="mb-0"> -->
                  {{ bank.name ? bank.name : "เลือกธนาคาร" }}
                  <!-- </p> -->
                </div>

                <div
                  v-if="bank.code || profile.bank"
                  class="icon-bank"
                >
                  <i class="fas fa-shield-check text-success" />
                </div>
              </div>
            </div>

            <div class="mt-1">
              <label
                for="Email"
                class="text-primary"
              > เลขบัญชี </label>

              <validation-provider
                #default="{ errors }"
                name="Bank Number"
                rules="required"
              >
                <b-form-input
                  v-model="profile.acc_no"
                  class="form-valid"
                  :state="errors.length > 0 ? false : null"
                  :disabled="!bank.code"
                  placeholder="กรุณากรอกเลขบัญชี"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>

            <div class="mt-1">
              <label
                for="accountName"
                class="text-primary"
              > ชื่อบัญชี </label>

              <validation-provider
                #default="{ errors }"
                name="accountName"
                rules="required"
              >
                <b-form-input
                  v-model="profile.acc_name"
                  class="form-valid"
                  :state="errors.length > 0 ? false : null"
                  :disabled="!bank.code"
                  placeholder="กรุณากรอกชื่อบัญชี"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>

            <div class="text-center">
              <button
                class="btn-gray-txt p-25 w-50 my-1"
                @click="setProfile()"
              >
                ยืนยันข้อมูล
              </button>
            </div>
          </div>
        </validation-observer>
      </b-modal>

      <!-- Modal Bank-->
      <b-modal
        ref="my-modal"
        hide-footer
        hide-header
        centered
      >
        <div class="row mt-1 mb-2">
          <div class="col-12 text-center mb-1">
            <h5 class="mb-0 text-primary">
              โปรดเลือกธนาคาร
            </h5>
          </div>
          <div
            v-for="item in items"
            :key="item.id"
            class="col-md-2 col-3 p-0 gtx"
          >
            <div
              class="card-bank-list"
              @click="selectBank(item.id)"
            >
              <img
                :src="`/bank/${item.code}.jpg`"
                :alt="item.code"
                width="100%"
                :class="{ active: isActive === item.id }"
                :style="{ opacity: getOpacity(item) }"
              >
            </div>
          </div>
        </div>
      </b-modal>

      <!-- Confirm logout -->
      <b-modal
        ref="modal-confirm-log-out"
        hide-footer
        hide-header
        centered
        size="sm"
      >
        <div class="text-center">
          <h3 class="text-primary">
            คุณต้องออกจากระบบหรือไม่
          </h3>
        </div>

        <div class="text-center">
          <button
            class="btn-confirm"
            @click="logout()"
          >
            ยืนยัน
          </button>

          <button
            class="btn-del"
            @click="$refs['modal-confirm-log-out'].hide()"
          >
            ยกเลิก
          </button>
        </div>
      </b-modal>
    </div>
    <ThisFooter />
    <!-- </div> -->
  </div>
</template>

<script>
import { BModal, BFormInput } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import { required } from '@validations'
import ThisFooter from './components/ThisFooter.vue'

export default {
  name: 'Home',
  components: {
    // ThisHeader,
    ThisFooter,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      bank: [],
      bankNumber: null,
      accountName: null,
      Loadmore: true,
      items: [],
      userData: JSON.parse(localStorage.getItem('userData')), // getlocalstorage
      balance: {
        balance: localStorage.getItem('balance'),
      },
      balance_withdraw: 0,
      balance_datecheck: '',
      isActive: null,
      profile: {
        bankname: '',
        acc_no: '',
        acc_name: '',
      },
    }
  },
  created() {
    this.$http.get('/lotto-suvarn/banklist/korean').then(res => {
      this.items = res.data.filter(item => item.status === 1)
    })
    if (!this.userData) {
      this.$router.push({ name: 'home' })
    }
  },
  mounted() {
    if (this.userData) {
      this.getBalance()
      this.GetUserProfile()
    }
  },
  methods: {
    async GetUserProfile() {
      this.Loadmore = true
      this.$http.get('users/profile').then(async ress => {
        this.profile = ress.data
        this.Loadmore = false
        // console.log(ress.data)
      })
    },
    setProfile() {
      this.$refs.setprofile.validate().then(success => {
        if (success) {
          this.Loadmore = true
          const obj = {
            bank: this.bank.code ? this.bank.code : this.profile.bank,
            acc_name: this.profile.acc_name,
            acc_no: this.profile.acc_no,
          }
          this.$http
            .post('users/UpdateProfile', obj)
            // eslint-disable-next-line no-unused-vars
            .then(async ress => {
              // eslint-disable-next-line
              this.Success("บันทึกข้อมูลสำเร็จ");
              this.GetUserProfile()
              this.$refs['add-profile'].hide()
            })
        }
      })
    },
    selectBank(id) {
      this.isActive = id
      this.bank = this.items.find(item => item.id === id)

      setTimeout(() => {
        this.$refs['my-modal'].hide()
      }, 500)
    },
    uploadProfile() {
      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      fileInput.accept = 'image/*'

      // Store a reference to the current 'this' context
      const self = this

      fileInput.addEventListener('change', event => {
        const selectedFile = event.target.files[0]
        if (selectedFile) {
          const reader = new FileReader()

          // eslint-disable-next-line func-names
          reader.onload = function () {
            const base64Image = reader.result

            // Use the 'self' reference to call the 'Upfile' function
            self.Upfile(base64Image)
          }
          reader.readAsDataURL(selectedFile)
        }
      })

      fileInput.click()
    },
    Upfile(img) {
      this.$http
        .post('users/uploadprofile/img', { img })
        // eslint-disable-next-line no-unused-vars
        .then(ress => {
          this.userData.profile_img = img
          localStorage.setItem('userData', JSON.stringify(this.userData))
          window.location.reload()
        })
    },
    ShowProfile() {
      if (this.userData.profile_img) {
        return this.userData.profile_img
      }
      return '/icon/user.png'
    },
    logout() {
      localStorage.removeItem('userData')
      this.$router.push({ name: 'home' })
      window.location.reload()
    },
    async getBalance() {
      this.Loadmore = true
      this.$http.get('balance/getbalance').then(async ress => {
        this.balance = ress.data
        this.balance_datecheck = moment()
          .tz('Asia/Bangkok')
          .format('YYYY-MM-DD HH:mm:ss')
        await this.sleep(500)
        // console.log(ress.data)
        this.Loadmore = false
        localStorage.setItem('balance', JSON.stringify(ress.data.balance))
      })
    },
    getOpacity(item) {
      return this.isActive === null || this.isActive === item.id ? '1' : '0.2'
    },
    Commas(x) {
      if (!x) {
        return 0
      }
      if (x % 1 !== 0) {
        return Number(x)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    sleep(time) {
      return new Promise(resolve => setTimeout(resolve, time))
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">Success</h3>',
        text: mes,
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        timer: 2000,
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">ขออภัยค่ะ !!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped></style>
